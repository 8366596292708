import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Typography, Container } from '@material-ui/core';

function Withdraw() {
    const [amount, setAmount] = useState('');
    const [withdrawalStatus, setWithdrawalStatus] = useState('');

    const handleWithdrawal = async () => {
        try {
            const response = await axios.post('http://localhost:443/api/v1.1/wallet/withdraw', { amount });
            setWithdrawalStatus(response.data.message);
        } catch (error) {
            console.error('Withdrawal failed:', error);
            setWithdrawalStatus('Withdrawal failed');
        }
    };

    return (
        <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
            <Typography variant="h5" gutterBottom>
                Withdrawal Form
            </Typography>
            <TextField
                label="Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
            />
            <Button variant="contained" color="primary" onClick={handleWithdrawal}>
                Withdraw
            </Button>
            <Typography variant="subtitle1" style={{ marginTop: '1rem' }}>
                {withdrawalStatus}
            </Typography>
        </Container>
    );
}

export default Withdraw;
