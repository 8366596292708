import React from "react";
import { Box, TextField } from "@mui/material";
import ArtworksCard from "../artworks/getArtworks"

const Home = () => {
    return (
        <Box>

            <ArtworksCard />
        </Box>
    )
};

export default Home;
