import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Button,
    Grid,
    Typography,
    Link,
    IconButton,
    InputAdornment
} from '@material-ui/core';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0',
        },

    },
}));



function CustomerRegister() {
    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [nationalIdCard, setNationalIdCard] = useState("");
    const [location, setLocation] = useState("");
    const [errors, setErrors] = useState({});

    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };

    const handlePhoneNumberChange = (phone) => {
        setPhoneNumber(`+${phone}`);
    };

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        // Validate email
        if (!email) {
            formIsValid = false;
            errors["email"] = "Please enter your email address";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            formIsValid = false;
            errors["email"] = "Please enter a valid email address";
        }

        // Validate password
        if (!password) {
            formIsValid = false;
            errors["password"] = "Please enter your password";
        } else if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/)) {
            formIsValid = false;
            errors["password"] = "Please enter a strong password. It should contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character. e.g Password_500$'";
        }


        // Validate name
        if (!name) {
            formIsValid = false;
            errors["name"] = "Please enter your name";
        }

        // validate phone number
        if (!phoneNumber) {
            formIsValid = false;
            errors["phoneNumber"] = "Please enter your phone number";
        } else if (!/^\+[1-9]\d{1,14}$/.test(phoneNumber)) {
            formIsValid = false;
            errors["phoneNumber"] = "Please enter a valid phone number starting with '+' and followed by a country code and phone number";
        }


        setErrors(errors);
        return formIsValid;
    };
    const handleRegister = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);
            formData.append("phoneNumber", phoneNumber);
            formData.append("nationalIdCard", nationalIdCard);
            formData.append("location", location);
            formData.append("name", name);

            try {
                const response = await axios.post(
                    "http://localhost:443/api/customer/user-register",
                    formData,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.status === 200) {
                    const accountId = response.data.account.id;
                    console.log(accountId)
                    toast.success("Your account has been created successfully!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    // Wait for a brief moment to allow the account creation to finish
                    await new Promise((resolve) => setTimeout(resolve, 6000));

                    // Fetch the user's account using the customer ID
                    const accountResponse = await axios.get(`http://localhost:443/api/accounts/account/user/${accountId}`);
                    const account = accountResponse.data;
                    console.log(account)

                    if (account) {
                        const accountIdFromResponse = account.id
                        // Redirect to the dynamic page with the account ID
                        window.location.href = `/send/request/${accountIdFromResponse}`;
                    } else {
                        alert("Account not found for the user");
                    }

                } else {
                    alert("Failed to create customer's account");
                }
            } catch (error) {
                toast.info(error.response.data.message, {
                    position: "top-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
    };

    return (
        <Grid container justify="center" alignItems="center" className={classes.gridContainer}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
                <Card>
                    <CardHeader title="Create account (CUSTOMER)" />
                    <CardContent>
                        <form onSubmit={handleRegister}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>

                                    <TextField
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        type="text"
                                        value={name}
                                        onChange={(nm) => setName(nm.target.value)}
                                    // error={errors.name ? true : false}
                                    // helperText={errors.name}
                                    />
                                </Grid>
                                <Grid item>

                                    <TextField
                                        fullWidth
                                        label="Email Address"
                                        variant="outlined"
                                        value={email}
                                        onChange={(mail) => setEmail(mail.target.value)}
                                    // error={errors.email ? true : false}
                                    // helperText={errors.email}
                                    />
                                </Grid>

                                <Grid item>
                                    <ReactPhoneInput
                                        inputStyle={{ width: '100%', height: "8vh" }}
                                        country={'us'}
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                        inputProps={{
                                            name: 'phoneNumber',
                                            required: true,
                                            autoFocus: true,
                                            placeholder: 'Phone Number'
                                        }}
                                        disableDropdown={false}
                                    />
                                    {/* <p>Phone Number: {phoneNumber}</p> */}
                                </Grid>
                                <Grid item>

                                    <TextField
                                        fullWidth
                                        label="National ID"
                                        variant="outlined"
                                        type="text"
                                        value={nationalIdCard}
                                        onChange={(nid) => setNationalIdCard(nid.target.value)}
                                    // error={errors.password ? true : false}
                                    // helperText={errors.password}
                                    />
                                </Grid>
                                <Grid item>

                                    <TextField
                                        fullWidth
                                        label="Location"
                                        variant="outlined"
                                        type="text"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                    // error={errors.password ? true : false}
                                    // helperText={errors.password}
                                    />
                                </Grid>
                                <Grid item>

                                    <TextField
                                        fullWidth
                                        label="Password"
                                        variant="outlined"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        error={errors.password ? true : false}
                                        helperText={errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handlePasswordToggle}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{
                                            backgroundColor: '#00A86B',
                                        }}
                                    >
                                        Register
                                    </Button>
                                </Grid>
                                <Typography align="center">
                                    Already have an account? <Link href="/">Login</Link>
                                </Typography>
                            </Grid>
                            <ToastContainer
                                // theme="dark"
                                position="top-right"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                            />
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default CustomerRegister;
