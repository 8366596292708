import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardContent,
    TextField,
    Button,
    Grid,
    Typography,
    Link,
    Box,
    IconButton,
    InputAdornment
} from '@material-ui/core';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Password } from '@mui/icons-material';
const Logo = require("../../BosskidsLogo.jpg")

function ResetPassword() {
    const { id, resetToken } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);

    const handlePasswordToggle = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        // Validate password
        if (!newPassword) {
            formIsValid = false;
            errors["password"] = "Please enter your password";
        } else if (!newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/)) {
            formIsValid = false;
            errors["password"] = "Please enter a strong password. It should contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character. e.g Password_500$'";
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const verifyResponse = await axios.get(`http://localhost:443/api/customer/reset-password/${id}/${resetToken}`);
                console.log('Token verification response:', verifyResponse.data);

                if (verifyResponse.data === 'Verified') {
                    // Token is verified, proceed with password reset
                    try {
                        const resetResponse = await axios.post(`http://localhost:443/api/customer/reset-password/${id}/${resetToken}`, { password: newPassword });
                        console.log('Password reset response:', resetResponse.data);

                        if (resetResponse.data === 'Password reset successful') {
                            toast.success('Password reset successful.', {
                                position: "top-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                            setTimeout(() => {
                                window.location.href = "/";
                            }, 2000);
                        } else {
                            toast.error(resetResponse.data, {
                                position: "top-left",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    } catch (error) {
                        toast.error(error.response.data.error, {
                            position: "top-left",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                } else {
                    toast.error("Invalid token. Password reset failed.", {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    toast.error("Invalid token. Password reset failed.", {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                } else {
                    toast.error("Network error. Password reset failed.", {
                        position: "top-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            }
        }
    };

    return (
        <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
            <Grid item xs={12} sm={8} md={6} lg={4}>
                <Card>
                    <CardHeader
                        title={
                            <Typography variant="h5" align="center" style={{ fontWeight: 'bold', color: '#00A86B' }}>
                                Reset Password
                            </Typography>
                        }
                        alignItems="center"
                    />

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={Logo} alt="Logo" style={{ width: '150px', height: '150px' }} />
                    </div>
                    <CardContent>
                        <form onSubmit={handleResetPassword}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        label="Enter new password"
                                        variant="outlined"
                                        type={showPassword ? 'text' : 'password'}
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        error={errors.password ? true : false}
                                        helperText={errors.password}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handlePasswordToggle}>
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{
                                            backgroundColor: '#00A86B',
                                        }}
                                    >
                                        Reset
                                    </Button>
                                    <Box></Box>
                                </Grid>
                            </Grid>
                        </form>
                        <ToastContainer
                            position="top-left"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default ResetPassword;
